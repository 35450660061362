
import { defineComponent, computed } from 'vue'
import { useStore } from '@/utils/composables'

import MyndshftTable from '@/components/table/table.vue'
import MyndshftTableCell from '@/components/table/cell.vue'
import { Conflict } from '@/components/shared/wizard/types'

export default defineComponent({
	name: 'payer-duplication-conflict',
	props: {
		type: String,
	},
	components: {
		[MyndshftTable.name]: MyndshftTable,
		[MyndshftTableCell.name]: MyndshftTableCell,
	},
	setup(props) {
		const store = useStore()

		const name = computed(() => {
			if (props.type === Conflict.NAME) {
				return store.state.payerWizard.payer.payer.name
			}
			return store.state.payerWizard.payer.payer.uniqueId
		})

		return {
			name,
		}
	},
})
