
import {
	defineComponent,
	onBeforeMount,
	ref,
	watch,
	SetupContext,
	PropType,
} from 'vue'
import { useStore } from '@/utils/composables'

import AuthRequired from '@/components/shared/configuration/auth-required.vue'
import MyndshftInput from '@/components/form/input.vue'
import { IForm } from '@/models/worklists/outcome'
import { fetchForms } from '@/service/worklist.http'

interface Value {
	name: string
	uniqueId: string
	authRequirement: string
	authSubmissionOONForm: string
}

export default defineComponent({
	name: 'myndshft-payer-profile',
	components: {
		[MyndshftInput.name]: MyndshftInput,
		[AuthRequired.name]: AuthRequired,
	},
	props: {
		value: { type: Object as PropType<Value>, required: true },
		newPayerSelectedForm: Object as PropType<IForm>,
	},
	setup(props, context: SetupContext) {
		const store = useStore()

		const selectedForm = ref<IForm | null | undefined>(
			props.newPayerSelectedForm
		)
		const loadingOptions = ref<any>(null)
		const formOptions = ref<IForm[]>([])

		const computeUniqueId = (name: string): string =>
			name
				?.trim()
				.toLowerCase()
				.replace(/(\s+|-)/g, '_') // Space to underscore
				.replace(/[^a-z0-9_]/g, '') // Only alphanumeric

		const update = (key: string, value: any) => {
			if (key === 'name') {
				// Update uniqueId when user changes payer name
				props.value.uniqueId = computeUniqueId(value)
			}

			context.emit('input', {
				...props.value,
				[key]: value,
			})
		}

		const loadOptions = async (search: string | null, id: string | null) => {
			formOptions.value = ((await fetchForms(search, id ? [id] : null)) ||
				[]) as IForm[]
			if (loadingOptions.value) {
				clearTimeout(loadingOptions.value)
				loadingOptions.value = null
			}
		}

		const onPaSearch = (search?: string | null) => {
			if (loadingOptions.value) {
				clearTimeout(loadingOptions.value)
			}
			loadingOptions.value = setTimeout(
				() => loadOptions(search || null, null),
				500
			)
		}

		watch(selectedForm, form => {
			store.commit('payer/setNewPayerSelectedForm', form)
		})

		onBeforeMount(() => onPaSearch(null))

		return {
			loadingOptions,
			formOptions,
			selectedForm,

			computeUniqueId,
			update,
			onPaSearch,
		}
	},
})
