
import { defineComponent, ref, computed, watch, SetupContext } from 'vue'

import RadioInput, {
	IAuthOption,
} from '@/components/shared/form/radio-input.vue'
import {
	NetworkAuthRequirement,
	NetworkAuthRequirementName,
} from '@server/api/models/fe/shared'
import { IAddress } from '@myndshft/types-mc-api'

export default defineComponent({
	name: 'auth-required',
	props: {
		authRequired: String,
	},
	components: {
		RadioInput,
	},
	setup(props, context: SetupContext) {
		const authRequired = ref(props.authRequired)

		watch(
			() => props.authRequired,
			isRequired => {
				authRequired.value = isRequired
			}
		)

		const options = computed(() => {
			return authOptions(authRequired.value)
		})

		const onAuthSelected = (selectedAuth: string) => {
			context.emit('input', selectedAuth)
		}

		return {
			options,
			onAuthSelected,
		}
	},
})

const authOptions = (authRequired = ''): IAuthOption[] => {
	const options: IAuthOption[] = [
		{
			label: NetworkAuthRequirementName.get(
				NetworkAuthRequirement.NO_AUTH
			) as NetworkAuthRequirement,
			inputValue: NetworkAuthRequirement.NO_AUTH,
		},
		{
			label: NetworkAuthRequirementName.get(
				NetworkAuthRequirement.OON
			) as NetworkAuthRequirement,
			inputValue: NetworkAuthRequirement.OON,
		},
		{
			label: NetworkAuthRequirementName.get(
				NetworkAuthRequirement.OON_PA
			) as NetworkAuthRequirement,
			inputValue: NetworkAuthRequirement.OON_PA,
		},
	]
	return options.map((option, index) => {
		option.checked = index === 0
		option.disabled = false
		if (option.inputValue.toString() === authRequired) {
			option.checked = true
		}
		return option
	})
}

export interface IAddressConfiguration {
	states?: any[]
	address: IAddress
	name: string
	uniqueName: string
}
