
import { defineComponent, ref, computed } from 'vue'
import { useStore } from '@/utils/composables'

import MyndshftAddress from './address.vue'
import MyndshftPayerProfile from '@/components/payer/wizard/steps/profile.vue'
import { IAddressFE } from '@server/api/models/fe/shared'

interface Value {
	name: string
	uniqueId: string
	authRequirement: string
	authSubmissionOONForm: string
}

export default defineComponent({
	name: 'payer-information',
	components: {
		[MyndshftPayerProfile.name]: MyndshftPayerProfile,
		[MyndshftAddress.name]: MyndshftAddress,
	},
	props: {
		newPayerSelectedForm: Object,
	},
	setup() {
		const store = useStore()

		const address = ref<IAddressFE>(store.state.payerWizard.payer.payer.address)

		const profile = ref<Value>({
			name: store.state.payerWizard.payer.payer.name,
			uniqueId: store.state.payerWizard.payer.payer.uniqueId,
			authRequirement: store.state.payerWizard.payer.payer.authRequirement,
			authSubmissionOONForm:
				store.state.payerWizard.payer.payer.authSubmissionOONForm,
		})

		const uniqueId = computed(
			() =>
				profile.value.uniqueId
					.trim()
					.toLowerCase()
					.replace(/(\s+|-)/g, '_') // Space to underscore
					.replace(/[^a-z0-9_]/g, '') // Only alphanumeric
		)

		const commit = () => {
			store.commit('payerWizard/payer/setAddress', address.value)
			store.commit('payerWizard/payer/setName', profile.value.name)
			store.commit('payerWizard/payer/setUniqueId', uniqueId.value)
			store.commit(
				'payerWizard/payer/setAuthRequirement',
				profile.value.authRequirement
			)
			store.commit(
				'payerWizard/payer/setAuthSubmissionOONForm',
				profile.value.authSubmissionOONForm
			)
		}

		return {
			address,
			profile,
			commit,
		}
	},
})
