
import { defineComponent, computed } from 'vue'
import { useStore } from '@/utils/composables'
import {
	IAddressFE,
	NetworkAuthRequirementName,
	TransactionTypes,
} from '@server/api/models/fe/shared'
import { IPayerTransaction } from '@server/api/models/fe/payer'

export default defineComponent({
	name: 'payer-confirmation',
	setup() {
		const store = useStore()
		const payer = computed(() => store.state.payerWizard.payer)
		const payerForm = computed(
			() => store.getters['payer/getNewPayerSelectedForm']
		)

		const formatAddress = (address: IAddressFE) => {
			const addressLines = [address.lineOne, address.lineTwo]
				.filter(Boolean)
				.join('\n')

			if (addressLines) {
				// Return address to display for confirmation
				const cityStateZip = `${address.city}, ${address.state ?? '??'} ${
					address.zip
				}`
				return [addressLines, cityStateZip].join('\n')
			}

			// Return empty address
			return ''
		}

		const payerInformation = computed(() => {
			return {
				'Payer Name': payer.value.payer.name,
				'Payer Unique Name': payer.value.payer.uniqueId,
				'Authorization Requirement': NetworkAuthRequirementName.get(
					payer.value.payer.authRequirement
				),
				'Default OON PA Form':
					payerForm?.value?.name || payer.value.payer.authSubmissionOONForm,
				Address: formatAddress(payer.value.payer.address),
			}
		})

		const findTransaction = (type: TransactionTypes) => {
			return (
				payer.value.transactions.find(
					(t: IPayerTransaction) => t.name === TransactionTypes[type]
				)?.isActive || false
			)
		}

		const transactions = computed(() => {
			return {
				'Benefits Eligibility': findTransaction(TransactionTypes.ELIGIBILITY),
				'Prior Auth Requirements': findTransaction(TransactionTypes.PRIOR_AUTH),
				'Prior Auth Submission': findTransaction(
					TransactionTypes.PRIOR_AUTH_SUBMISSION
				),
				'Pharmacy Prior Auth Submission': findTransaction(
					TransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION
				),
				'Patient Responsibility': findTransaction(
					TransactionTypes.PATIENT_RESPONSIBILITY
				),
			}
		})

		const activation = computed(() => {
			return {
				'Activate on Creation?': payer.value.payer.status,
			}
		})

		return {
			payer,
			payerInformation,
			transactions,
			activation,
		}
	},
})
