
import { defineComponent, ref, watch, computed, PropType } from 'vue'
import { NetworkAuthRequirement } from '@server/api/models/fe/shared'

export default defineComponent({
	props: {
		id: String,
		option: Object as PropType<IAuthOption>,
		onAuthSelected: { type: Function, required: true },
		isReadOnly: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const option = ref(props.option)

		watch(
			() => props.option,
			pOption => {
				option.value = pOption
			}
		)

		const state = computed(() => {
			return {
				id: props.id || Math.random().toString(36).substring(2, 12),
				inputValue: option?.value?.inputValue,
				checked: option?.value?.checked,
				disabled: option?.value?.disabled,
				label: option?.value?.label,
			}
		})

		return {
			state,
			onSelected: props.onAuthSelected,
		}
	},
})

export interface IAuthOption {
	id?: string
	checked?: boolean
	disabled?: boolean
	label: NetworkAuthRequirement
	inputValue: string
}
