
import { defineComponent, ref, computed, watch, PropType } from 'vue'

import {
	TransactionTypes,
	TransactionNames,
} from '@server/api/models/fe/shared'
import { IPayerTransaction } from '@server/api/models/fe/payer'

import MessageBlock from '@/components/shared/message-block.vue'

export default defineComponent({
	props: {
		transactions: {
			type: Array as PropType<IPayerTransaction[]>,
			default: () => [],
		},
		isConfigActive: Boolean,
		canEdit: Boolean,
	},
	components: {
		MessageBlock,
	},
	setup(props, context) {
		const createTransactionMap = (transactions: IPayerTransaction[]) => {
			const map: Record<string, IPayerTransaction> = {}
			transactions.forEach(transaction => {
				map[transaction.name] = transaction
			})
			return map
		}

		const transactionMap = ref(createTransactionMap(props.transactions))

		const canSetPAS = computed(
			() => transactionMap.value[TransactionTypes.PRIOR_AUTH].isActive
		)

		const showInfoMessage = computed<boolean>(() => {
			const BVTransaction = transactionMap.value[TransactionTypes.ELIGIBILITY]
			const PfrTransaction =
				transactionMap.value[TransactionTypes.PATIENT_RESPONSIBILITY]

			return PfrTransaction?.isActive && !BVTransaction?.isActive
		})

		const updateTransactions = () => {
			context.emit('update:transactions', Object.values(transactionMap.value))
		}

		const handleParentToggleChange = (
			isParentActive: boolean,
			childToggle: TransactionTypes
		) => {
			if (!isParentActive) {
				transactionMap.value[childToggle].isActive = false
			}
			updateTransactions()
		}

		watch(
			() => props.transactions,
			transactions => {
				transactionMap.value = createTransactionMap(transactions)
			}
		)

		return {
			transactionMap,
			canSetPAS,
			showInfoMessage,
			TransactionNames,
			TransactionTypes,
			handleParentToggleChange,
			updateTransactions,
		}
	},
})
