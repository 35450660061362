
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	name: 'message-block',
	props: {
		message: String,
		messageType: {
			type: String as PropType<'error' | 'warning' | 'info'>,
			default: () => 'info',
		},
	},
})
