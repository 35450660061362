var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('wizard-steps',{attrs:{"id":"payer-wizard","steps":_vm.steps}}),_c('myndshft-panel',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{ref:"form"},[(
						_vm.wizardStep === 0 &&
						!_vm.state.matches('wizard.steps.duplication.confirmation') &&
						!_vm.conflictType
					)?_c('payer-information',{ref:"payerInformation",attrs:{"newPayerSelectedForm":_vm.newPayerSelectedForm}}):(_vm.wizardStep === 0 && _vm.conflictType)?_c('payer-duplication-conflict',{attrs:{"type":_vm.conflictType}}):(
						_vm.wizardStep === 0 &&
						_vm.state.matches('wizard.steps.duplication.confirmation')
					)?_c('payer-duplication-check'):_vm._e(),_c('keep-alive',[(_vm.wizardStep === 1)?_c('payer-wizard-transactions'):_vm._e()],1),(_vm.wizardStep === 2)?_c('payer-confirmation'):_vm._e(),_c('nav',[_c('v-btn',{staticClass:"button ms-btn",class:{ 'is-loading': _vm.isTransitioning },style:({
							visibility:
								_vm.wizardStep === 0 &&
								!_vm.state.matches('wizard.steps.duplication.confirmation') &&
								!_vm.conflictType
									? 'hidden'
									: 'visible',
						}),attrs:{"id":"back-button","large":"","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.transition(_vm.PREV)}}},[_vm._v(" Back ")]),(
							!_vm.conflictType &&
							!_vm.state.matches('wizard.steps.creatingPayer') &&
							!_vm.state.matches('wizard.steps.success')
						)?_c('v-btn',{staticClass:"button ms-btn ms-btn-primary is-pulled-right",class:{ 'is-loading': _vm.isTransitioning },attrs:{"disabled":!_vm.$canEditPayer() || invalid,"large":"","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.transitionNext.apply(null, arguments)}}},[(
								_vm.state.matches('wizard.steps.confirmation') ||
								_vm.state.matches('wizard.steps.creating') ||
								_vm.state.matches('wizard.steps.success')
							)?_c('span',{attrs:{"id":"create-payer-button"}},[_vm._v("Create")]):_c('span',{attrs:{"id":"next-button"}},[_vm._v("Next")])]):_vm._e()],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }