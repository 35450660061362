import { render, staticRenderFns } from "./duplicationConflict.vue?vue&type=template&id=ecab98aa&scoped=true"
import script from "./duplicationConflict.vue?vue&type=script&lang=ts"
export * from "./duplicationConflict.vue?vue&type=script&lang=ts"
import style0 from "./duplicationConflict.vue?vue&type=style&index=0&id=ecab98aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecab98aa",
  null
  
)

export default component.exports