
import {
	defineComponent,
	computed,
	onBeforeMount,
	onBeforeUnmount,
	ref,
	watchEffect,
} from 'vue'
import { useStore } from '@/utils/composables'

import PayerInformation from '@/components/payer/wizard/steps/payerInformation.vue'
import DuplicationConflict from '@/components/payer/wizard/steps/duplicationConflict.vue'
import DuplicationCheck from '@/components/payer/wizard/steps/duplicationCheck.vue'
import PayerWizardTransactions from '@/components/payer/wizard/steps/payerWizardTransactions.vue'
import PayerConfirmation from '@/components/payer/wizard/steps/confirmation.vue'

import WizardSteps from '@/components/shared/wizard/steps.vue'
import { useWizard } from '@/components/shared/wizard/steps'
import { WizardStep, Conflict } from '@/components/shared/wizard/types'
import { useVuexMachine } from '@/store/utils/machine'

import wizardMachine from '@/store/payer-wizard/machine'
import { MachineEvent, NULL_EVENT } from '@/store/utils/machine'

const wizardSteps: WizardStep[] = [
	{
		label: 'Payer Information',
		icon: 'user',
	},
	{
		label: 'Transactions',
		icon: 'cog',
	},
	{
		label: 'Confirmation',
		icon: 'check',
	},
]

export default defineComponent({
	name: 'payer-wizard',
	components: {
		[WizardSteps.name]: WizardSteps,
		[PayerInformation.name]: PayerInformation,
		[DuplicationConflict.name]: DuplicationConflict,
		[DuplicationCheck.name]: DuplicationCheck,
		[PayerWizardTransactions.name]: PayerWizardTransactions,
		[PayerConfirmation.name]: PayerConfirmation,
	},
	setup() {
		const store = useStore()
		const { steps, currentStep } = useWizard(wizardSteps)
		const { state, transition } = useVuexMachine(
			store,
			wizardMachine,
			'payerWizard'
		)

		const wizardStep = computed(
			() => store.getters['payerWizard/wizardStep'] as number
		)
		const newPayerSelectedForm = computed(
			() => store.getters['payer/getNewPayerSelectedForm']
		)
		const conflictType = computed(() => {
			const conflict = state.value.matches('wizard.steps.duplication.conflict')
			if (conflict) {
				if (state.value.matches('wizard.steps.duplication.conflict.name')) {
					return Conflict.NAME
				}
				return Conflict.UNIQUE_NAME
			}
			return null
		})

		onBeforeMount(() => {
			transition(NULL_EVENT)
			store.commit('payer/setNewPayerSelectedForm', null)
		})

		onBeforeUnmount(() => {
			store.dispatch('payerWizard/reset')
		})

		watchEffect(() => {
			currentStep.value = wizardStep.value
		})

		// State Machine Events
		const PREV: MachineEvent = { type: 'PREV' }
		const NEXT: MachineEvent = { type: 'NEXT' }

		const payerInformation = ref(null)
		const commitPayerInformation = () => {
			const payerInfo = payerInformation.value as any
			payerInfo.commit()
		}

		const form = ref(null)
		const validateForm = () => (form.value as any).reportValidity()
		const isTransitioning = ref(false)

		const transitionNext = () => {
			isTransitioning.value = true
			if (state.value.matches('wizard.steps.payerInformation')) {
				commitPayerInformation()
			}
			if (validateForm()) {
				transition(NEXT)
			}
			isTransitioning.value = false
		}

		return {
			steps,
			state,
			conflictType,
			wizardStep,
			PREV,
			NEXT,
			form,
			payerInformation,
			isTransitioning,
			newPayerSelectedForm,

			commitPayerInformation,
			transition,
			transitionNext,
			validateForm,
		}
	},
})
