var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!!Object.keys(_vm.transactionMap).length)?_c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.TransactionNames.get(_vm.TransactionTypes.ELIGIBILITY))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"benefits-eligibility-toggle","disabled":!_vm.isConfigActive,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":_vm.updateTransactions},model:{value:(_vm.transactionMap[_vm.TransactionTypes.ELIGIBILITY].isActive),callback:function ($$v) {_vm.$set(_vm.transactionMap[_vm.TransactionTypes.ELIGIBILITY], "isActive", $$v)},expression:"transactionMap[TransactionTypes.ELIGIBILITY].isActive"}})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.TransactionNames.get(_vm.TransactionTypes.PRIOR_AUTH))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"prior-auth-requirements-toggle","disabled":!_vm.isConfigActive,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":function($event){return _vm.handleParentToggleChange(
						$event,
						_vm.TransactionTypes.PRIOR_AUTH_SUBMISSION
					)}},model:{value:(_vm.transactionMap[_vm.TransactionTypes.PRIOR_AUTH].isActive),callback:function ($$v) {_vm.$set(_vm.transactionMap[_vm.TransactionTypes.PRIOR_AUTH], "isActive", $$v)},expression:"transactionMap[TransactionTypes.PRIOR_AUTH].isActive"}})],1)]),_c('div',{staticClass:"columns sub-toggle"},[_c('div',{staticClass:"column",class:{ disabled: !_vm.canSetPAS }},[_vm._v(" "+_vm._s(_vm.TransactionNames.get(_vm.TransactionTypes.PRIOR_AUTH_SUBMISSION))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"prior-auth-submission-toggle","disabled":!_vm.isConfigActive || !_vm.canSetPAS,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":_vm.updateTransactions},model:{value:(
					_vm.transactionMap[_vm.TransactionTypes.PRIOR_AUTH_SUBMISSION].isActive
				),callback:function ($$v) {_vm.$set(_vm.transactionMap[_vm.TransactionTypes.PRIOR_AUTH_SUBMISSION], "isActive", $$v)},expression:"\n\t\t\t\t\ttransactionMap[TransactionTypes.PRIOR_AUTH_SUBMISSION].isActive\n\t\t\t\t"}})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.TransactionNames.get(_vm.TransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"pharmacy-prior-auth-submission-toggle","disabled":!_vm.isConfigActive,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":_vm.updateTransactions},model:{value:(
					_vm.transactionMap[_vm.TransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION]
						.isActive
				),callback:function ($$v) {_vm.$set(_vm.transactionMap[_vm.TransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION]
						, "isActive", $$v)},expression:"\n\t\t\t\t\ttransactionMap[TransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION]\n\t\t\t\t\t\t.isActive\n\t\t\t\t"}})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.TransactionNames.get(_vm.TransactionTypes.PATIENT_RESPONSIBILITY))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"patient-responsibility-toggle","disabled":!_vm.isConfigActive,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":_vm.updateTransactions},model:{value:(
					_vm.transactionMap[_vm.TransactionTypes.PATIENT_RESPONSIBILITY].isActive
				),callback:function ($$v) {_vm.$set(_vm.transactionMap[_vm.TransactionTypes.PATIENT_RESPONSIBILITY], "isActive", $$v)},expression:"\n\t\t\t\t\ttransactionMap[TransactionTypes.PATIENT_RESPONSIBILITY].isActive\n\t\t\t\t"}})],1)]),(_vm.showInfoMessage)?_c('message-block',{attrs:{"message":"Ensure this payer should not be available for eligibility transactions, otherwise enable the Eligibility transaction or only enable PA transactions"}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }