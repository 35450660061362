
import { defineComponent, computed, ref } from 'vue'
import { useStore } from '@/utils/composables'

import { TransactionTypes } from '@server/api/models/fe/shared'
import { IPayerTransaction } from '@server/api/models/fe/payer'

import PayerTransactions from '@/components/payer/payer-transactions.vue'

export default defineComponent({
	name: 'payer-wizard-transactions',
	components: {
		PayerTransactions,
	},
	setup() {
		const store = useStore()

		const initialTransactionState = (): IPayerTransaction[] => [
			{ name: TransactionTypes.ELIGIBILITY, isActive: false },

			{
				name: TransactionTypes.PRIOR_AUTH,
				isActive: false,
			},
			{
				name: TransactionTypes.PRIOR_AUTH_SUBMISSION,
				isActive: false,
			},
			{
				name: TransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION,
				isActive: false,
			},
			{
				name: TransactionTypes.PATIENT_RESPONSIBILITY,
				isActive: false,
			},
		]
		const transactions = ref<IPayerTransaction[]>(initialTransactionState())

		const payerName = computed(() => store.state.payerWizard.payer.payer.name)

		const activatePayer = computed<boolean>({
			get: () => {
				const status = store.state.payerWizard.payer.payer.status
				return status === 'ACTIVE' ? true : false
			},
			set: value => {
				store.commit(
					'payerWizard/payer/setStatus',
					value === true ? 'ACTIVE' : 'INACTIVE'
				)
			},
		})

		const updatePayerTransactions = (transactionList: IPayerTransaction[]) => {
			transactions.value = transactionList
			store.commit('payerWizard/payer/setTransactions', transactions.value)
		}

		const onActivateToggle = (activated: boolean) => {
			if (!activated) {
				updatePayerTransactions(initialTransactionState())
			}
		}

		return {
			payerName,
			activatePayer,
			transactions,
			onActivateToggle,
			updatePayerTransactions,
		}
	},
})
