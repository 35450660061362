
import { defineComponent } from 'vue'

import PayerWizard from '@/components/payer/wizard/wizard.vue'

export default defineComponent({
	components: {
		[PayerWizard.name]: PayerWizard,
	},
	beforeRouteLeave(to, _, next) {
		if (to.name === 'payer-id') {
			next()
		} else {
			next(
				confirm(
					'If you leave the wizard, your unsaved changes will be discarded.'
				) as any
			)
		}
	},
})
