
import { defineComponent, computed, SetupContext, PropType } from 'vue'

import MyndshftInput from '@/components/form/input.vue'
import MyndshftSelect from '@/components/form/select.vue'

import { states } from '@/components/shared/configuration/states'

import { useFormatting } from '@/dependencies/formatting'
import { IAddressFE } from '@server/api/models/fe/shared'

export default defineComponent({
	name: 'myndshft-address',
	components: {
		[MyndshftInput.name]: MyndshftInput,
		[MyndshftSelect.name]: MyndshftSelect,
	},
	props: {
		value: { type: Object as PropType<Partial<IAddressFE>>, required: true },
		canEdit: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, context: SetupContext) {
		const update = (key: string, value: any) => {
			context.emit('input', {
				...props.value,
				[key]: value,
			})
		}

		const isRequired = computed(() => {
			const { lineOne, lineTwo, city, state, zip } = props.value
			const address = {
				lineOne,
				lineTwo,
				city,
				state,
				zip,
			}

			// If there is a value, user is filling out form so require validation
			return !!Object.values(address).some(Boolean)
		})

		const onZipCode = (value: string) => {
			const { toZipCode } = useFormatting()
			props.value.zip = toZipCode(value)
		}

		return {
			states,
			update,
			onZipCode,
			isRequired,
		}
	},
})
