
import { defineComponent, computed } from 'vue'
import { useStore } from '@/utils/composables'

import MyndshftTable from '@/components/table/table.vue'
import MyndshftTableCell from '@/components/table/cell.vue'

export default defineComponent({
	name: 'payer-duplication-check',
	components: {
		[MyndshftTable.name]: MyndshftTable,
		[MyndshftTableCell.name]: MyndshftTableCell,
	},
	setup() {
		const store = useStore()

		const duplicates = computed(() => store.state.payerWizard.duplicates)

		const payerId = computed(() => store.state.payerWizard.payer.payer.uniqueId)

		const columns = [
			{
				field: '',
				label: 'Payer Id',
			},
			{
				field: '',
				label: '',
			},
		]

		return {
			payerId,
			duplicates,
			columns,
		}
	},
})
